
//公共库
import { Message } from "element-ui";
import { Component, Vue } from "vue-property-decorator";

//本地引入
import DTCls from "@/utils/datetime";
import { saveAs } from "@/api/request/base";
import Pagination from "@/comp/Pagination/index.vue";
import { getUserRecordList, exportUserRecordList } from "@/api/request/user";

//组件
@Component({
  name: "RecordList",
  components: {
    Pagination,
  },
})

//组件函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private currentItem: any = {};
  private listLoading: boolean = true;
  private detailDialog: boolean = false;

  //类型数据
  private typeOptions = [
    { value: -1, label: "全部" },
    { value: 1, label: "音频" },
    { value: 2, label: "视频" },
  ];

  //时间参数
  get pickerOptions(): any {
    return {
      shortcuts: [
        {
          text: "今日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getTodayStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "昨日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getYesterdayStartTime()), new Date(DTCls.getYesterdayEndTime())]);
          },
        },
        {
          text: "本周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurWeekStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastWeekStartTime()), new Date(DTCls.getLastWeekEdnTime())]);
          },
        },
        {
          text: "本月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurMonthStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastMonthStartTime()), new Date(DTCls.getLastMonthEndTime())]);
          },
        },
        {
          text: "最近一周",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近三个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "全部",
          onClick(picker: any) {
            picker.$emit("pick", []);
            // that.listQuery.time = []
          },
        },
      ],
      disabledDate(time: Date) {
        return time.getTime() > Date.now();
      },
    };
  }

  //请求数据
  private listQuery: any = {
    //页面数据
    page: 1,
    row: 20,

    //临时数据
    time: [],

    //时间数据
    end_time: "",
    start_time: "",

    //筛选数据
    type: -1,
    user_id: undefined,
    blogger_id: undefined,
  };

  //创建
  created() {
    //初始化时间
    this.initTime();

    //获取数据
    this.getList();
  }

  //获取数据
  private async getList() {
    //判断显示
    if (!this.listQuery.time || this.listQuery.time.length != 2) return Message({ message: "请选择日期！", type: "error", duration: 5 * 1000 });

    //显示加载
    this.listLoading = true;

    //数据赋值
    if (this.listQuery.time && this.listQuery.time.length == 2) {
      this.listQuery.end_time = `${this.listQuery.time[1]} 23:59:59`;
      this.listQuery.start_time = `${this.listQuery.time[0]} 00:00:00`;
    } else {
      this.listQuery.end_time = "";
      this.listQuery.start_time = "";
    }
    if (this.listQuery.user_id) this.listQuery.user_id = Number(this.listQuery.user_id);
    if (this.listQuery.blogger_id) this.listQuery.blogger_id = Number(this.listQuery.blogger_id);

    //获取记录
    const { data } = await getUserRecordList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏加载
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //初始化时间
  private initTime(): void {
    //数据赋值
    this.listQuery.end_time = `${DTCls.getCurDateTimeYMD()}`;
    this.listQuery.start_time = `${DTCls.getCurDateTimeYMD()}`;
    this.listQuery.time = [`${this.listQuery.start_time}`, `${this.listQuery.end_time}`];
    this.listQuery.start_time += "  00:00:00";
    this.listQuery.end_time += "  23:59:59";
  }

  //关闭对话框
  private btnCancel(): void {
    //隐藏对话框
    this.detailDialog = false;

    //清空数据
    this.currentItem = {};
  }

  //重置查询
  private handleReset(): void {
    //清空数据
    this.listQuery.page = 1;
    this.listQuery.type = -1;
    this.listQuery.user_id = undefined;
    this.listQuery.blogger_id = undefined;

    //初始化时间
    this.initTime();

    //获取列表
    this.getList();
  }

  //处理导出
  private async exportHandler() {
    //判断显示
    if (!this.listQuery.time || this.listQuery.time.length != 2) return Message({ message: "请选择日期！", type: "error", duration: 5 * 1000 });

    //显示等待
    this.listLoading = true;

    //数据赋值
    if (this.listQuery.time && this.listQuery.time.length == 2) {
      this.listQuery.end_time = `${this.listQuery.time[1]} 23:59:59`;
      this.listQuery.start_time = `${this.listQuery.time[0]} 00:00:00`;
    } else {
      this.listQuery.end_time = "";
      this.listQuery.start_time = "";
    }
    if (this.listQuery.user_id) this.listQuery.user_id = Number(this.listQuery.user_id);
    if (this.listQuery.blogger_id) this.listQuery.blogger_id = Number(this.listQuery.blogger_id);

    //获取数据
    const { data } = await exportUserRecordList(this.listQuery);

    //保存数据
    saveAs(data, "用户记录查询列表");

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取数据
    this.getList();
  }

  //类型数据
  private typeFormat(row: any): any {
    if (row.type === 2) return "视频";
    if (row.type === 1) return "音频";
  }

  //处理详情
  private handleDetail(row: any): void {
    //数据赋值
    this.currentItem = row;

    //显示对话框
    this.detailDialog = true;
  }
}
